"use client"
import React, {useEffect, useState} from 'react'
import style from "../menu.module.scss";
import {BiShowAlt, BiSolidShow} from "react-icons/bi";
import {useAxios} from "hooks/useAxios";
import useGebruiker from "hooks/useGebruiker";
import {useSWRConfig} from "swr";
export default function ShowTekstPaden() {

    const {mutate} = useSWRConfig()
    const {post} = useAxios()
    const {ingelogdeGebruiker} = useGebruiker()
    const [instelling, setInstelling] = useState()
    useEffect(() => {
        if (!ingelogdeGebruiker) return
        const instelling2 = ingelogdeGebruiker?.instellingen?.filter(d => d.instelling === "Paden Tonen")
        setInstelling(instelling2)
    }, [ingelogdeGebruiker])

    function handlePadSetting() {

        let option = {
            instelling_id: null,
            label: null,
            value: null
        }
        if (instelling && instelling.length != 0) {
            option.instelling_id = instelling[0].id
            if (instelling[0]?.gebruikers_instelling?.ingestelde_optie === 1) {
                option.label = "Uitzetten"
                option.value = 2
            } else {
                option.label = "Aanzetten"
                option.value = 1
            }
        } else {
            option.label = "Aanzetten"
            option.value = 1
        }

        post(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/instelling`, option).then(x => {
            mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik`)
        })
    }

    return <div>
        {
            instelling && instelling.length !== 0 && (
                ingelogdeGebruiker?.mkg_teams?.some(x => x.id === 2) && instelling &&
                instelling[0]?.gebruikers_instelling?.ingestelde_optie === 1 ?
                    <BiSolidShow onClick={handlePadSetting}/> :
                    <BiShowAlt onClick={handlePadSetting}/>
            )


        }
    </div>
}