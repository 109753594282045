"use client"


import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {useRouter} from "next/navigation";
import {useTekstClient} from "../../../hooks/useTekstClient";
import revalidateAction from "../../../lib/actions";
import SelectBedrijven from "../../SelectAlleBedrijven/SelectBedrijven/SelectBedrijven";
import {useAxios} from "../../../hooks/useAxios";
import {useLoading} from "../../../hooks/useLoading";
import {signOut, useSession} from "next-auth/react"
import useGebruiker from "../../../hooks/useGebruiker";
import styleDropdown from "./gebruiker-dropdown.module.scss";
import {IoMdArrowDropdown} from "react-icons/io";
import Link from "next/link";

export function LogOffButton() {

    const router = useRouter();
    const {tekst, isLoadingTekst} = useTekstClient();
    const {post} = useAxios();
    const logOff = async () => {
        await signOut({callbackUrl: '/mijn-mkg/support'});
    }

    return <MenuItem className={`hover:text-cyan`} onClick={logOff}>
        <small
            className="block w-full px-4 py-1 text-left text-xs text-gray-700 data-[focus]:text-kleur-cyan2 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 uppercase underline cursor-pointer"
        >
            {tekst('pagina:menu:uitloggen')}
        </small>
    </MenuItem>
}


export function GebruikerDropDown({ preIngelogd, preNamensBedrijf, companies }) {

    const { update } = useSession();
    const { ingelogdeGebruiker, ingelogdeGebruikerIsLoading, namensBedrijf, namensBedrijfIsLoading} = useGebruiker()
    const {tekst, isLoadingTekst} = useTekstClient();
    const {setIsLoading} = useLoading()
    const router = useRouter();
    const [disableLicense, setDisableLicense] = useState(true);


    /** When search a company (namensbedrijf) the menu not being closed fix **/
    useEffect(() => {
        setDisableLicense(false)
        const el = document.querySelector('#dropdownUser')
        if(!el) return
        function handleKeyDown(e) {
            if (e.code === "Space") {
                e.stopPropagation();
            }
        }
        el.addEventListener('keydown', handleKeyDown);

        return () => {
            el.removeEventListener('keydown', handleKeyDown)
        }
    }, []);


    const handleSaveBedrijf = bedrijf => {
        setDisableLicense(true)
        if (bedrijf != null) {
            setIsLoading(true)
            update({bedrijfsId: bedrijf.id}).then(() => {
                revalidateAction('actionCounter')
            }).finally(() => {
                setIsLoading(false)
                location.reload();
            })
        } else {
            setIsLoading(true)
            update({bedrijfsId: null}).then(() => {
            }).finally(() => {
                setIsLoading(false)
                location.reload();
            })
        }

    }




    return <Menu id={'dropdownUser'} as="div" className="relative inline-block text-left">
        <MenuButton
            className={`inline-flex w-full justify-center items-center gap-x-1.5 px-1 py-2 text-xs uppercase ${styleDropdown.menuBtn}`}>

            <section
                className={`flex justify-center items-center ${styleDropdown.ingelogdeGebruikerContainer}`}>
                {ingelogdeGebruiker ? <div>{ingelogdeGebruiker.naam}</div> : (ingelogdeGebruikerIsLoading && preIngelogd ? <div>{preIngelogd.naam}</div> : <></>)}
                {namensBedrijf || preNamensBedrijf
                    ? <>
                        {namensBedrijf
                            ? <><div>|</div><div>{namensBedrijf.naam}</div></>
                            : (preNamensBedrijf ? <><div>|</div><div>{preNamensBedrijf.naam}</div></> : <></>)
                        }
                    </>
                    : <></>
                }

            </section>

            <IoMdArrowDropdown aria-hidden="true" className="-mr-1 h-5 w-5"/>
        </MenuButton>

        <MenuItems
            className="z-[999999] absolute right-0 z-10 mt-2 py-2 w-80 origin-top-right  bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
            <div className="py-1 uppercase text-xs">

                <MenuItem>
                    <Link
                        href={`/mijn-mkg/mijn-gegevens/gebruikersbeheer/account/${ingelogdeGebruiker?.id}`}
                        className="block px-4 py-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                        Account
                    </Link>
                </MenuItem>

                {ingelogdeGebruiker?.groep?.mkger &&
                    <MenuItem>
                        <Link
                            href="/mijn-mkg/instellingen"
                            className="block px-4 py-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            {tekst('pagina:menu:Instellingen')}
                        </Link>
                    </MenuItem>}


                <LogOffButton/>

                <section className={`my-2`}>
                    <div
                        className={`block px-4 py-3 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 border-y border-inherit`}>
                        <p>{tekst('algemeen:menu:inloggen_namens')}</p>

                        <SelectBedrijven
                            getOptionValue={option => option.id} // value is bedrijf, dus hier id
                            value={namensBedrijf}
                            options={companies}
                            isMulti={false}
                            isClearable={ingelogdeGebruiker?.groep?.mkger}
                            onChange={handleSaveBedrijf}
                        />

                    </div>
                </section>


                {disableLicense ? <MenuItem disabled>
                    <p
                        className="block px-4 pt-1 !pb-1 text-xs text-slate-700 underline opacity-25"
                    >
                        {tekst('pagina:menu:daglicentie ophalen')}
                    </p>
                </MenuItem> : ingelogdeGebruiker?.groep?.mkger && (namensBedrijf && namensBedrijf !== "" ?
                    <MenuItem>
                        <Link
                            href="/mijn-mkg/mijn-gegevens/producten/daglicentie"
                            className="block px-4 pt-1 !pb-1 text-xs text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            {tekst('pagina:menu:daglicentie ophalen')}
                        </Link>
                    </MenuItem>
                    : <MenuItem disabled>
                        <p
                            className="block px-4 pt-1 !pb-1 text-xs text-slate-700 underline opacity-25"
                        >
                            {tekst('pagina:menu:daglicentie ophalen')}
                        </p>
                    </MenuItem>)}
            </div>
        </MenuItems>
    </Menu>


}